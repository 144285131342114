'use client';

import { Prose } from '@/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize';
import EmojiText from '@/components/EmojiText/EmojiText';
import StyledSwitch from '@/components/Switch/StyledSwitch';
import SingleTab from '@/components/Tab/SingleTab';
import { Switch, Tab } from '@headlessui/react';
import { mdiAlertCircle, mdiSwapHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import { calcAPCA, fontLookupAPCA } from 'apca-w3';
import { colorParsley, colorToHex } from 'colorparsley';
import { motion } from 'motion/react';
import { Inter } from 'next/font/google';
import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { hex, score } from 'wcag-contrast';
import SingleColorPicker from './SingleColorPicker';

const inter = Inter({
	weight: 'variable',
	subsets: ['latin'],
});

const FONT_WEIGHTS = {
	100: 'Thin',
	200: 'Extra Light',
	300: 'Light',
	400: 'Regular',
	500: 'Medium',
	600: 'Semi Bold',
	700: 'Bold',
	800: 'Extra Bold',
	900: 'Black',
};

type ColorParsleyColor = [number, number, number, number, boolean, string];

interface WcagLevel {
	ratio: string;
	apcaContrast: number;
	wcagLevel: 'AA' | 'AAA';
	wcagMinTextSizePx: 0 | 24;
}

const WCAG_LEVELS: WcagLevel[] = [
	{
		ratio: '7:1',
		apcaContrast: 75,
		wcagLevel: 'AAA',
		wcagMinTextSizePx: 0,
	},
	{
		ratio: '4.5:1',
		apcaContrast: 60,
		wcagLevel: 'AAA',
		wcagMinTextSizePx: 24,
	},
	{
		ratio: '4.5:1',
		apcaContrast: 60,
		wcagLevel: 'AA',
		wcagMinTextSizePx: 0,
	},
	{
		ratio: '3:1',
		apcaContrast: 48,
		wcagLevel: 'AA',
		wcagMinTextSizePx: 24,
	},
];

const satisfiesWcagLevel = (
	contrast: number,
	textSizePx: number,
): WcagLevel | undefined => {
	return WCAG_LEVELS.find(
		({ apcaContrast, wcagMinTextSizePx }) =>
			contrast >= apcaContrast && textSizePx >= wcagMinTextSizePx,
	);
};

const ContrastTool = () => {
	const searchParams = useSearchParams();

	const [foregroundColor, setForegroundColor] = useState<ColorParsleyColor>(
		colorParsley(searchParams?.get('fg') ?? '#383838'),
	);
	const [backgroundColor, setBackgroundColor] = useState<ColorParsleyColor>(
		colorParsley(searchParams?.get('bg') ?? 'white'),
	);

	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

	const contrast = useMemo(
		() => calcAPCA(foregroundColor, backgroundColor),
		[foregroundColor, backgroundColor],
	);

	useEffect(() => {
		const fg = colorToHex(foregroundColor);
		const bg = colorToHex(backgroundColor);
		const url = new URL(window.location.href);
		url.searchParams.set('fg', fg);
		url.searchParams.set('bg', bg);
		window.history.replaceState({}, '', url.toString());
	}, [foregroundColor, backgroundColor]);

	return (
		<section
			aria-label="Contrast calculator"
			className="mx-auto flex w-full max-w-2xl flex-col rounded-2xl pt-2 text-base dark:bg-slate-900"
		>
			<form className="relative grid grid-cols-2 gap-4 px-2">
				<SingleColorPicker
					label="Voorgrondkleur"
					color={`#${colorToHex(foregroundColor)}`}
					setColor={(color) =>
						setForegroundColor(colorParsley(color))
					}
					isOpacityAllowed
				/>
				<SingleColorPicker
					label="Achtergrondkleur"
					color={`#${colorToHex(backgroundColor)}`}
					setColor={(color) =>
						setBackgroundColor(colorParsley(color))
					}
				/>
				<button
					type="button"
					className="group absolute left-1/2 top-4 -translate-x-1/2 rounded-full bg-primary-700 p-2 font-bold text-white shadow-md transition-all active:scale-105 hover:scale-110"
					onClick={() => {
						setForegroundColor(backgroundColor);
						setBackgroundColor(foregroundColor);
					}}
					title="Kleuren wisselen"
					aria-label="Kleuren wisselen"
				>
					<Icon
						className="transition-all group-active:rotate-180 group-hover:-rotate-12"
						path={mdiSwapHorizontal}
						size={1}
					/>
				</button>
			</form>

			<section
				aria-label="Contrast uitslag"
				className="grow rounded-b-2xl p-4"
			>
				<h2 className="mb-4 text-center text-2xl text-primary-800 dark:text-primary-100">
					Ik gebruik het voor<span aria-hidden>...</span>
				</h2>
				<Tab.Group
					selectedIndex={selectedTabIndex}
					onChange={setSelectedTabIndex}
				>
					<Tab.List className="flex justify-center gap-4">
						<SingleTab label="Informatie" />
						<SingleTab label="Decoratie" />
					</Tab.List>
					<Tab.Panels className="mt-4">
						<Tab.Panel>
							<TextResultSummary contrast={contrast} />
							{Math.abs(contrast) >= 45 && (
								<TextResultTable
									foregroundColorHex={colorToHex(
										foregroundColor,
									)}
									backgroundColorHex={colorToHex(
										backgroundColor,
									)}
									contrast={contrast}
								/>
							)}
						</Tab.Panel>
						<Tab.Panel className="p-6 text-center font-bold text-primary-800 md:p-12 dark:text-primary-100">
							Decoratie bevat geen informatie voor de gebruiker en
							heeft daarom geen contrasteisen. Wees er zeker van
							dat de gebruiker niks mist als de decoratie er niet
							zou zijn.
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</section>

			<section
				aria-label="Contrast meten informatie"
				className="mx-auto my-8 flex flex-col"
			>
				<h2 className="text-center text-2xl text-primary-800 dark:text-primary-100">
					Meer informatie
				</h2>
				<Prose>
					<p>
						Vermijd altijd dunne lettertypes (dikte 100). Hoewel
						&apos;thin&apos; altijd een uitslag geeft, is dit niet
						altijd leesbaar. Soms is de tekst dunner dan een pixel
						op een scherm of valt het niet precies op een pixel.
						Hierdoor wordt de tekst vaag.
					</p>
					<p>
						Zorg dat de instelling van voor- en achtergrondkleur
						goed staat. De uitkomst kan anders zijn als je de
						kleuren omwisselt.
					</p>
					<p>
						Let op: deze tool komt niet overeen met WCAG 2. Bij WCAG
						2 wordt een berekening gebruikt om het contrast te meten
						die niet overeenkomt met hoe mensen kleuren waarnemen.
						Een betere formule is{' '}
						<span lang="en">
							Advanced Perceptual Contrast Algorithm (APCA).
						</span>{' '}
						Waarschijnlijk wordt deze formule gebruikt voor WCAG 3,
						maar dit is nog niet bevestigd. We kunnen deze formule
						wel beter gebruiken dan de formule van WCAG 2, omdat
						deze wetenschappelijk bewezen is. Het maakt onze
						producties toegankelijker dan WCAG 2. Lees{' '}
						<a href="https://digitaaltoegankelijk.nl/nieuws/advanced-perceptual-contrast-algorithm-wcag-3/">
							meer over APCA en het verschil
						</a>
						.
					</p>
				</Prose>
			</section>
		</section>
	);
};

const TextResultSummary = ({ contrast }: { contrast: number }) => {
	const summaryText = useMemo(() => {
		const absoluteContrast = Math.abs(contrast);
		if (!absoluteContrast) {
			return 'Dit contrast is te laag om een voorspelling te doen. 😕';
		} else if (absoluteContrast < 15) {
			return 'Onzichtbaar 😶';
		} else if (absoluteContrast < 30) {
			return 'Net zichtbaar 😳';
		} else if (absoluteContrast < 45) {
			return 'Iets meer contrast alsjeblieft! 😐';
		} else if (absoluteContrast < 60) {
			return 'Sommige combinaties kunnen misschien, voor grote tekst 🤓';
		} else if (absoluteContrast < 75) {
			return 'Dit is aardig contrast 👍';
		} else if (absoluteContrast < 90) {
			return 'Goed contrast ✨';
		} else if (absoluteContrast < 100) {
			return 'Perfect contrast! 🎯';
		} else if (absoluteContrast >= 100) {
			return 'Dit contrast is waarschijnlijk te hoog! Daardoor is het soms minder prettig 😕';
		}
		return 'Dit contrast is te laag om een voorspelling te doen. 😕';
	}, [contrast]);

	return (
		<motion.div
			key={summaryText}
			className="mb-4 flex w-full flex-col items-center justify-center rounded-lg bg-primary-700 px-8 py-4 text-white shadow-md"
			initial={{
				scaleY: 0.8,
				opacity: 0,
			}}
			animate={{
				scaleY: 1,
				opacity: 1,
				transition: {
					delay: 1,
				},
			}}
		>
			<span className="text-center text-xl font-bold">
				<EmojiText>{summaryText}</EmojiText>
			</span>
		</motion.div>
	);
};

const TextResultTable = ({
	foregroundColorHex,
	backgroundColorHex,
	contrast,
}: {
	foregroundColorHex: string;
	backgroundColorHex: string;
	contrast: number;
}) => {
	const [wcagCompatibilityVisible, setWcagCompatibilityVisible] =
		useState<boolean>(false);

	const fontTable = useMemo(() => fontLookupAPCA(contrast), [contrast]);

	let wcag2CompatibilityScore = score(
		hex(foregroundColorHex, backgroundColorHex),
	).replace('Large', 'groot');
	if (wcag2CompatibilityScore === 'Fail') {
		wcag2CompatibilityScore = undefined;
	}

	return (
		<div className="overflow-x-auto">
			<table className={`w-full ${inter.className}`}>
				<thead>
					<tr>
						<th className="text-left">Dikte</th>
						<th className="ps-4 text-left">Minimaal</th>
						<th className="ps-4 text-center">
							{wcagCompatibilityVisible
								? 'APCA (aanbevolen)'
								: 'Level'}
						</th>
						{wcagCompatibilityVisible && (
							<th className="ps-4 text-center">
								WCAG 2 (verouderd)
							</th>
						)}
					</tr>
				</thead>
				<tbody>
					{Object.entries(FONT_WEIGHTS)
						.reverse()
						.map(([weightNumber, weightName]) => {
							const weightNumberInt = parseInt(weightNumber);

							let level = satisfiesWcagLevel(
								Math.abs(contrast),
								fontTable[weightNumberInt / 100],
							);

							if (!level) {
								level = satisfiesWcagLevel(
									Math.abs(contrast),
									24,
								);
							}

							// Get the font sizes in pixels and rem
							const fontSizePx =
								Math.ceil(
									Math.max(
										fontTable[weightNumberInt / 100],
										level?.wcagMinTextSizePx ?? 0,
									) / 2,
								) * 2;
							const fontSizeRem = fontSizePx * 0.0625;
							const fontSizeRemRounded =
								Math.ceil(fontSizeRem * 5000) / 5000;

							// Hide sizes above 4rem
							if (
								(weightNumberInt < 800 && fontSizeRem > 6) ||
								fontSizeRem > 30
							) {
								return null;
							}

							return (
								<tr
									key={weightNumber}
									className="border-b-2 border-b-black/5 dark:border-b-white/10"
								>
									<td className="w-full grow overflow-hidden text-left">
										<div
											className="line-clamp-1 rounded-md p-4"
											style={{
												fontWeight: weightNumber,
												fontSize: `${fontSizeRem}rem`,
												color: `#${foregroundColorHex}`,
												backgroundColor: `#${backgroundColorHex}`,
											}}
										>
											{weightName}
										</div>
									</td>
									<td className="text-left">
										<div className="w-24 ps-4 font-bold">
											<span>{fontSizeRemRounded}rem</span>
											<br />
											<span className="text-sm opacity-80">
												{fontSizePx}px
											</span>
										</div>
									</td>
									<td
										className="text-center"
										title={
											level?.wcagLevel
												? `Niveau ${level.wcagLevel}`
												: 'Voldoet niet'
										}
									>
										<WCAGLevelChip
											level={level?.wcagLevel}
										/>
									</td>
									{wcagCompatibilityVisible && (
										<td
											className="text-center"
											title={
												wcag2CompatibilityScore
													? `Niveau ${wcag2CompatibilityScore}`
													: 'Voldoet niet'
											}
										>
											<WCAGLevelChip
												level={wcag2CompatibilityScore}
											/>
										</td>
									)}
								</tr>
							);
						})}
				</tbody>
			</table>
			<Switch.Group>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
				<div
					className="group m-1 flex cursor-pointer items-center justify-end p-3 transition-all focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 motion-safe:active:scale-95"
					onClick={() =>
						setWcagCompatibilityVisible(!wcagCompatibilityVisible)
					}
				>
					<Switch.Label className="mr-4 origin-left cursor-pointer text-base font-semibold">
						Toon ook verouderde compatibiliteit met WCAG 2
					</Switch.Label>
					<StyledSwitch
						checked={wcagCompatibilityVisible}
						onChange={setWcagCompatibilityVisible}
					/>
				</div>
			</Switch.Group>
		</div>
	);
};

const WCAGLevelChip = ({
	level,
}: {
	level: 'AAA' | 'AA' | 'A' | undefined;
}) => {
	return (
		<>
			{level ? (
				<div
					className={`ml-2 inline-block ${
						level === 'AAA' ? 'bg-green-700' : 'bg-yellow-600'
					} rounded-full px-3 py-1 font-bold text-white`}
				>
					{level}
				</div>
			) : (
				<div className="ml-2 inline-block rounded-full bg-red-600 p-1 text-white dark:bg-red-300 dark:text-red-800">
					<span className="sr-only">Contrast niet voldoende</span>
					<Icon path={mdiAlertCircle} size={1} />
				</div>
			)}
		</>
	);
};

export default ContrastTool;
